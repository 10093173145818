import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The Mega Menu component is a navigation element that displays a large menu with multiple sub-menus and options. It is commonly used in websites with a large number of pages or products, allowing users to easily navigate and discover content.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/megamenu/guideline-megamenu-anatomy.svg",
      "alt": "anatomy guideline mega menu component"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Trigger / Action:`}</strong>{` The clickable element that opens the Mega Menu.`}</li>
      <li parentName="ol"><strong parentName="li">{`Tab (Optional):`}</strong>{` The container that displays the mega menu navigation and content.`}</li>
      <li parentName="ol"><strong parentName="li">{`Menu Item:`}</strong>{` The menu item that appears when a user select can show the content menu beside.`}</li>
      <li parentName="ol"><strong parentName="li">{`Content Menu:`}</strong>{` A contain a content of the menu item that selected or active.`}</li>
      <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` The container that displays the mega menu navigation and content.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`For use cases where the breadth of navigation options becomes too extensive to display effectively within a primary navigation configuration, the application may use the mega menu navigation panel. Secondary and tertiary navigation options are organized in left-aligned columns (on grid) with gutters between each column.`}</p>
    <p><strong parentName="p">{`Common places buttons appear :`}</strong>{` Mobile app homepage , website homepage and other pages when needed etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>
        Do display secondary and tertiary navigation options within a mega menu associated with the primary navigation
        label in the masthead
      </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>
        Don't mix instances of primary navigation positioned in the masthead with side navigation and/or the mega menu.
        Use only one chosen navigation schema across the entire application user experience.
      </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`The Mega Menu component should be fully accessible to all users, including those with disabilities. The things that can be consider with this is also the following:`}</p>
    <ol>
      <li parentName="ol">{`Semantic HTML: Use semantic HTML elements to ensure that the component can be understood by assistive technology. For example, use headings to indicate the main categories and list items to indicate the sub-menu items.`}</li>
      <li parentName="ol">{`Clear labels: Provide clear and descriptive labels for the navigation elements, such as the navigation header and the sub-menu items. Use language that is easy to understand and avoids jargon or complex terminology.`}</li>
      <li parentName="ol">{`Keyboard navigation: Ensure that the Mega Menu can be navigated using a keyboard, not just a mouse. This means using proper focus management and providing keyboard shortcuts for opening and closing the menu and sub-menus.`}</li>
      <li parentName="ol">{`ARIA attributes: Use ARIA (Accessible Rich Internet Applications) attributes to provide additional information to assistive technology, such as indicating which menu item is currently focused or selected.`}</li>
      <li parentName="ol">{`Color contrast: Ensure that there is sufficient color contrast between the text and background to make it easy to read, especially for users with low vision.`}</li>
      <li parentName="ol">{`Animation and visual cues: Use animations or other visual cues to indicate when sub-menus are being opened or closed, but ensure that they do not cause distractions or confusion.`}</li>
    </ol>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Use the tab option on the mega menu if the category division is up to 3 levels so that the menu content already contains specific categories that are easy for users to recognize" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/megamenu/mega-menu-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="the displayed menu content must be specific so as not to confuse the user and if it is less specific so it needs to be lowered or further divided into menu categories." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/megamenu/mega-menu-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Mega Menu :`}</p>
    <ul>
      <li parentName="ul">{`The Mega Menu should be organized into logical categories that can be easily navigated.`}</li>
      <li parentName="ul">{`Use headings and subheadings to break up the content and make it easier to scan.`}</li>
      <li parentName="ul">{`Use images or other visual aids to help illustrate the content.`}</li>
      <li parentName="ul">{`Include relevant links to related pages or products.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Make sure the writing of menu items and menu content is short or specific and easy to understand" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/megamenu/mega-menu-content-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Writing menu items and menu content is too detailed or long so that it distracts the user while reading" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/megamenu/mega-menu-content-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      